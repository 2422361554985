import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'

import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/styles/main.css'

createApp(App).use(createPinia()).use(router).mount('#app')
