import { createRouter, createWebHashHistory } from 'vue-router'
// import {
//   useAuthStore
// } from '@/store'
import routes from './routes'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Middleware.
// @ts-ignore
// router.beforeEach((to, _, next) => {
//   const store = useAuthStore()

//   if (to.meta.requiresAuth) {
//     const token = store.getToken
//     if (token) {
//       next()
//     } else {
//       next({ name: 'home' })
//     }
//   } else {
//     // if it's logged in, redirect to dashboard
//     const token = store.getToken
//     if (token) {
//       next({ name: 'Dashboard' })
//     }
//   }
// })

export default router
